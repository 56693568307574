import { API, ENV, ROUTES } from '@core/constants';
import { type ITranslation } from '@core/i18n/types';
import { type RootState } from '@core/store';
import { setAdditionalFieldsData } from '@core/store/reducers/appAdditionalFieldsSettings';
import { setAppConfigData } from '@core/store/reducers/appConfigSettings';
import {
  setAppSessionData,
  setSelectedPaymentMethodData,
  setSelectedPaymentMethodDetailsData,
} from '@core/store/reducers/appSessionSetting';
import {
  setBannerMessage,
  setKyc4PingProcessingData,
  setKyc4ProcessingData,
  setKyc5PingProcessingData,
  setKyc5ProcessingData,
  setSessionData,
} from '@core/store/reducers/currentSettings';
import {
  type IKycProcedureMutationData,
  type IMethodDetails,
  type IMethodDetailsArgs,
  type IMethodDetailsResponse,
  type IMetricsMutationData,
  type IPayment,
  type IPaymentRequestMutationData,
  type IPaymentResponse,
  type IPciPayment,
  type IProceedKycProcedureResponse,
  type IProceedPaymentMutationData,
  type ITransactionDetailsResponse,
  type IVirtualPaymentResponse,
  type LogRequestMutationData,
  type ProceedKycProcedure,
  type ProceedKycProcedureEvent,
  type ProceedKycProcedureStatus,
  type TransactionDetails,
  type VirtualPayment,
} from '@core/types';
import {
  type IGroupedPaymentMethods,
  type IGroupedPaymentMethodsResponse,
} from '@core/types/methods';
import {
  decodeJwt,
  omit,
  parseSearchParams,
  replaceURLParams,
  transformObjToCamelCase,
  transformObjToSnakeCase,
} from '@core/utils';
import Hotjar from '@hotjar/browser';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

interface IAuthorizePayload {
  sessionId: string;
}

interface IAuthorizeResponse {
  jwt: string;
}

export const cashierApi = createApi({
  reducerPath: 'cashierApi',
  invalidationBehavior: 'immediately',
  baseQuery: fetchBaseQuery({
    baseUrl: ENV.VITE_CASHIER_API_URL,
    prepareHeaders: (headers, { getState }) => {
      const isStatusPage =
        window.location.pathname === ROUTES.STATUS_TRANSACTION;

      if (!isStatusPage) {
        const token = (getState() as RootState).currentSettings.jwt ?? '';

        if (token) {
          headers.set('Authorization', `Bearer ${token}`);
        }
        return headers;
      }

      if (isStatusPage) {
        const transactionId =
          new URL(location.href).searchParams.get('uuid') ??
          (getState() as RootState).currentSettings.transactionId;
        const identifier =
          new URL(location.href).searchParams.get('identifier') ?? '';
        const jwt = (getState() as RootState).currentSettings.jwt ?? '';

        const token = transactionId ?? identifier ?? jwt;

        if (token) {
          headers.set('Authorization', `Bearer ${token}`);
        }
        return headers;
      }
    },
  }),
  endpoints: (build) => ({
    getTranslation: build.query<ITranslation, unknown>({
      query: () => ({
        url: API.CONFIG.TRANSLATION,
      }),
    }),
    sendMetric: build.mutation<unknown, IMetricsMutationData>({
      query: (payload) => ({
        body: payload,
        method: 'POST',
        url: API.LOGGING.METRIC,
      }),
    }),
    sendLog: build.mutation<unknown, IMetricsMutationData>({
      query: (payload) => ({
        method: 'POST',
        url: API.LOGGING.LOG,
        body: { ...payload, log_timestamp: Date.now() },
      }),
    }),
    makeLog: build.mutation<unknown, LogRequestMutationData>({
      query: ({ headers, ...body }) => ({
        headers,
        method: 'POST',
        url: API.LOGGING.LOG,
        body: { ...body, log_timestamp: Date.now() },
      }),
    }),
    deletePaymentInstrument: build.mutation<unknown, number>({
      query: (id) => ({
        method: 'DELETE',
        url: replaceURLParams(
          API.PAYMENT_INSTRUMENTS.REMOVE_PAYMENT_INSTRUMENT,
          { id }
        ),
      }),
    }),
    makePayment: build.mutation<IPayment, IPaymentRequestMutationData>({
      transformResponse: (response: IPaymentResponse) =>
        transformObjToCamelCase(response) as IPayment,
      query: (payload) => ({
        body: payload,
        method: 'POST',
        url: API.PAYMENT.ROOT,
      }),
    }),
    makeVirtualPayment: build.mutation<
      VirtualPayment,
      IPaymentRequestMutationData
    >({
      transformResponse: (response: IVirtualPaymentResponse) =>
        transformObjToCamelCase(response) as VirtualPayment,
      query: (payload) => ({
        body: payload,
        method: 'POST',
        url: API.PAYMENT.VIRTUAL_PAYMENT,
      }),
    }),
    proceedPayment: build.mutation<IPayment, IProceedPaymentMutationData>({
      transformResponse: (response: IPaymentResponse) =>
        transformObjToCamelCase(response) as IPayment,
      query: ({ transactionUuid, ...rest }) => ({
        body: rest,
        method: 'POST',
        url: replaceURLParams(API.PAYMENT.PROCEED, {
          uuid: transactionUuid,
        }),
      }),
    }),
    makePciPayment: build.mutation<IPciPayment, IPaymentRequestMutationData>({
      query: (payload) => ({
        body: payload,
        method: 'POST',
        url: API.PAYMENT.ROOT,
      }),
      transformResponse: (response: IPaymentResponse) => {
        const transformedResponse = {
          ...(transformObjToCamelCase(
            omit(response, ['embedded_page_properties'])
          ) as object),
          embeddedPageProperties: response.embedded_page_properties,
        };
        return transformedResponse as IPciPayment;
      },
    }),
    makeVirtualPciPayment: build.mutation<
      IPciPayment,
      IPaymentRequestMutationData
    >({
      query: (payload) => ({
        body: payload,
        method: 'POST',
        url: API.PAYMENT.VIRTUAL_PAYMENT,
      }),
      transformResponse: (response: IPaymentResponse) => {
        const transformedResponse = {
          ...(transformObjToCamelCase(
            omit(response, ['embedded_page_properties'])
          ) as object),
          embeddedPageProperties: response.embedded_page_properties,
        };
        return transformedResponse as IPciPayment;
      },
    }),
    getTransaction: build.query<TransactionDetails, string>({
      query: (uuid) => ({
        url: replaceURLParams(API.PAYMENT.TRANSACTION_DETAILS, { uuid }),
      }),
      transformResponse: (response: ITransactionDetailsResponse) =>
        transformObjToCamelCase(response) as TransactionDetails,
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        dispatch(
          setAppConfigData({
            presetColorV2: data.theme ?? 'light',
            presetColorV1: data.theme ?? 'Default',
          })
        );
      },
    }),
    getPaymentMethods: build.query<IGroupedPaymentMethods, string>({
      query: (intent) => ({
        url: API.PAYMENT_METHODS,
        params: {
          intent,
        },
      }),
      transformResponse: (response: IGroupedPaymentMethodsResponse) =>
        transformObjToCamelCase(response) as IGroupedPaymentMethods,
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(setBannerMessage(data?.bannerMessage));
            dispatch(setSelectedPaymentMethodData(data?.groups[0]?.methods[0]));
          }
        } catch (error) {}
      },
    }),
    getGroupedPaymentMethods: build.query<IGroupedPaymentMethods, string>({
      query: (intent) => ({
        url: API.PAYMENT_METHODS,
        params: {
          intent,
        },
      }),
      transformResponse: (response: IGroupedPaymentMethodsResponse) =>
        transformObjToCamelCase(response) as IGroupedPaymentMethods,
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(setBannerMessage(data?.bannerMessage));
            dispatch(setSelectedPaymentMethodData(data?.groups[0]?.methods[0]));
          }
        } catch (error) {}
      },
    }),
    // TODO: refactor types
    pingProceedKyc4Procedure: build.query<
      { status: ProceedKycProcedureStatus },
      {
        event?: ProceedKycProcedureEvent;
      } & {
        processingData: Record<string, string>;
      } & IPaymentRequestMutationData &
        Partial<Omit<IKycProcedureMutationData, 'processing_data'>>
    >({
      query: ({ event, ...body }) => ({
        body,
        method: 'POST',
        url: API.INTEGRATION.KYC.PROCESSING,
        ...(event && {
          params: {
            event,
          },
        }),
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(setKyc4PingProcessingData(data.status));
          }
        } catch (error) {}
      },
    }),
    // TODO: refactor types
    pingProceedKyc5Procedure: build.query<
      { status: ProceedKycProcedureStatus },
      {
        event?: ProceedKycProcedureEvent;
      } & {
        processingData: Record<string, string>;
      } & IPaymentRequestMutationData &
        Partial<Omit<IKycProcedureMutationData, 'processing_data'>>
    >({
      query: ({ event, ...body }) => ({
        body,
        method: 'POST',
        url: API.INTEGRATION.KYC.PROCESSING,
        ...(event && {
          params: {
            event,
          },
        }),
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(setKyc5PingProcessingData(data.status));
          }
        } catch (error) {}
      },
    }),
    getPaymentMethodDetails: build.query<IMethodDetails, IMethodDetailsArgs>({
      transformResponse: (response: IMethodDetailsResponse) => {
        return transformObjToCamelCase(response) as IMethodDetails;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          if (data) {
            dispatch(setSelectedPaymentMethodDetailsData(data));
            dispatch(setAdditionalFieldsData(data.fields));
          }
        } catch {}
      },
      query: (payload) => {
        const data = payload?.fields
          ? {
              ...(transformObjToSnakeCase(omit(payload, ['fields'])) as object),
              fields: payload?.fields,
            }
          : transformObjToSnakeCase(payload);

        return {
          body: data,
          method: 'POST',
          url: API.PAYMENT_METHOD_DETAILS,
        };
      },
    }),
    proceedKycProcedure: build.mutation<
      ProceedKycProcedure,
      {
        event?: ProceedKycProcedureEvent;
      } & IKycProcedureMutationData &
        IPaymentRequestMutationData
    >({
      transformResponse: (response: IProceedKycProcedureResponse) =>
        transformObjToCamelCase(response) as ProceedKycProcedure,
      query: ({ event, ...body }) => ({
        body,
        method: 'POST',
        url: API.INTEGRATION.KYC.PROCESSING,
        ...(event && {
          params: {
            event,
          },
        }),
      }),
      async onQueryStarted({ event }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            if (event === 'KYC_4') {
              dispatch(setKyc4ProcessingData(data));
            }
            if (event === 'KYC_5') {
              dispatch(setKyc5ProcessingData(data));
            }
          }
        } catch (error) {}
      },
    }),
    authorize: build.query<IAuthorizeResponse, IAuthorizePayload>({
      query: (payload) => ({
        method: 'POST',
        url: replaceURLParams(API.AUTHORIZE, {
          sessionId: payload.sessionId ?? '',
        }),
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            const queryParamIntent = parseSearchParams(
              window.location.search,
              'intent'
            );
            const queryParamAmount = parseSearchParams(
              window.location.search,
              'amount'
            );
            const decodedJwt = decodeJwt(data.jwt);
            if (ENV.VITE_ENVIRONMENT === 'prod') {
              Hotjar.identify(decodedJwt?.merchantCustomerId ?? '', {
                customerType: decodedJwt?.customerType ?? '',
              });
            }
            dispatch(
              setSessionData({
                jwt: data.jwt,
                locale: decodedJwt?.locale ?? '',
                sessionId: decodedJwt?.sub ?? '',
                intent: queryParamIntent as Intent,
                country: decodedJwt?.country ?? '',
                currency: decodedJwt?.currency ?? '',
                scale: String(decodedJwt?.scale) ?? '',
                sandbox: decodedJwt?.virtualMode ?? false,
              })
            );
            dispatch(
              setAppSessionData({
                jwt: data.jwt,
                amount: queryParamAmount ?? 10,
                locale: decodedJwt?.locale ?? '',
                sessionId: decodedJwt?.sub ?? '',
                intent: queryParamIntent as Intent,
                country: decodedJwt?.country ?? '',
                currency: decodedJwt?.currency ?? '',
                scale: String(decodedJwt?.scale) ?? '',
                sandbox: decodedJwt?.virtualMode ?? false,
              })
            );
          }
        } catch {}
      },
    }),
  }),
});

export const isCashierApiError = (
  error: unknown
): error is ICashierApiErrorData => {
  return (
    typeof error === 'object' &&
    error !== null &&
    'data' in error &&
    typeof (error as Record<string, unknown>).data === 'object'
  );
};

interface ICashierApiErrorData {
  data: {
    code: string;
    message: string;
    timestamp: number;
    traceId: string;
  };
}

export const {
  useSendLogMutation,
  useMakeLogMutation,
  useSendMetricMutation,
  useLazyAuthorizeQuery,
  useGetTransactionQuery,
  useMakePaymentMutation,
  useGetPaymentMethodsQuery,
  useProceedPaymentMutation,
  useMakePciPaymentMutation,
  useLazyGetTransactionQuery,
  useLazyGetTranslationQuery,
  useMakeVirtualPaymentMutation,
  useProceedKycProcedureMutation,
  useGetPaymentMethodDetailsQuery,
  usePingProceedKyc4ProcedureQuery,
  usePingProceedKyc5ProcedureQuery,
  useMakeVirtualPciPaymentMutation,
  useGetGroupedPaymentMethodsQuery,
  useDeletePaymentInstrumentMutation,
} = cashierApi;
