export const PROVIDERS = Object.freeze({
  PCI: 'PCI',
  VOLT: 'VOLT',
  INPAY: 'INPAY',
  PRAXIS: 'PRAXIS',
  SKRILL: 'SKRILL',
  EUPAGO: 'EUPAGO',
  BOODIL: 'BOODIL',
  GIGADAT: 'GIGADAT',
  PAYCASH: 'PAYCASH',
  WEBPAYZ: 'WEBPAYZ',
  HEXOPAY: 'HEXOPAY',
  NODAPAY: 'NODAPAY',
  CITIZEN: 'CITIZEN',
  SPAYZIO: 'SPAYZIO',
  EZEEBILL: 'EZEEBILL',
  HELP2PAY: 'HELP2PAY',
  CHECKOUT: 'CHECKOUT',
  APPLEPAY: 'APPLEPAY',
  NETELLER: 'NETELLER',
  MIFINITY: 'MIFINITY',
  PAYMENT_IQ: 'PAYMENT_IQ',
  SAFECHARGE: 'SAFECHARGE',
  GATECHARGE: 'GATECHARGE',
  CASHTOCODE: 'CASHTOCODE',
  MICROPAYMENT: 'MICROPAYMENT',
  PAYWITHCLICK: 'PAYWITHCLICK',
  PCI_DOLCEPAY: 'PCI_DOLCEPAY',
  PCI_WORLDCARD: 'PCI_WORLDCARD',
  PCI_SAFECHARGE: 'PCI_SAFECHARGE',
  PAYMENT_CENTER: 'PAYMENT_CENTER',
  BOODIL_NATIONALBET: 'BOODIL_NATIONALBET',
});

export enum PROVIDERS_ENUM {
  APPLEPAY = 'APPLEPAY',
  BOODIL = 'BOODIL',
  BOODIL_NATIONALBET = 'BOODIL_NATIONALBET',
  CASHTOCODE = 'CASHTOCODE',
  CHECKOUT = 'CHECKOUT',
  CITIZEN = 'CITIZEN',
  EUPAGO = 'EUPAGO',
  EZEEBILL = 'EZEEBILL',
  GATECHARGE = 'GATECHARGE',
  GIGADAT = 'GIGADAT',
  HELP2PAY = 'HELP2PAY',
  HEXOPAY = 'HEXOPAY',
  INPAY = 'INPAY',
  MICROPAYMENT = 'MICROPAYMENT',
  MIFINITY = 'MIFINITY',
  NETELLER = 'NETELLER',
  NODAPAY = 'NODAPAY',
  PAYCASH = 'PAYCASH',
  PAYMENT_CENTER = 'PAYMENT_CENTER',
  PAYMENT_IQ = 'PAYMENT_IQ',
  PAYWITHCLICK = 'PAYWITHCLICK',
  PCI = 'PCI',
  PCI_DOLCEPAY = 'PCI_DOLCEPAY',
  PCI_SAFECHARGE = 'PCI_SAFECHARGE',
  PCI_WORLDCARD = 'PCI_WORLDCARD',
  PRAXIS = 'PRAXIS',
  SAFECHARGE = 'SAFECHARGE',
  SKRILL = 'SKRILL',
  SPAYZIO = 'SPAYZIO',
  VOLT = 'VOLT',
  WEBPAYZ = 'WEBPAYZ',
}

export type PROVIDER_NAME = `${PROVIDERS_ENUM}`;
