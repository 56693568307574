import { type Field } from '@core/types';
import { formattedFieldValue } from '@core/utils';
import { createAction, createReducer } from '@reduxjs/toolkit';

interface IAdditionalFieldsSettings {
  additionalFieldValues: Record<string, boolean | number | string>;
  collapsibleFields: Field[];
  isAdditionalFieldValuesInvalid: boolean;
  notCollapsibleFields: Field[];
}

const initialState: IAdditionalFieldsSettings = {
  collapsibleFields: [],
  notCollapsibleFields: [],
  additionalFieldValues: {},
  isAdditionalFieldValuesInvalid: false,
};

export const setAdditionalFieldsData = createAction<Field[]>(
  'appAdditionalFieldsSettings/setAdditionalFieldsData'
);

export const setAdditionalFieldValues = createAction<Field[]>(
  'appAdditionalFieldsSettings/setAdditionalFieldValues'
);

export const setIsAdditionalFieldValuesValid = createAction<boolean>(
  'appAdditionalFieldsSettings/setIsAdditionalFieldValuesValid'
);

export const appAdditionalFieldsSettings = createReducer(
  initialState,
  (builder) => {
    builder.addCase(setAdditionalFieldsData, (state, action) => {
      if (action.payload.length > 0) {
        const notCollapsible = [];
        const collapsible = [];

        for (const field of action.payload) {
          if (field.alwaysVisible) {
            notCollapsible.push(field);
          } else {
            collapsible.push(field);
          }
        }

        state.collapsibleFields = collapsible.sort((a, b) => a.order - b.order);
        state.notCollapsibleFields = notCollapsible.sort(
          (a, b) => a.order - b.order
        );
      } else {
        state.collapsibleFields = [];
        state.notCollapsibleFields = [];
      }
    });
    builder.addCase(setAdditionalFieldValues, (state, action) => {
      const values = action.payload?.reduce<Record<string, string>>(
        (acc, { name, value }) => {
          acc[name] = formattedFieldValue(name, value);

          return acc;
        },
        {}
      );

      state.additionalFieldValues = values;
    });
    builder.addCase(setIsAdditionalFieldValuesValid, (state, action) => {
      state.isAdditionalFieldValuesInvalid = action.payload;
    });
  }
);
